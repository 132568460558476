import { useEffect, createRef } from 'react';

type UseScrollClassOptions = {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
};

export function useScrollClass(count: number, className: string, options?: UseScrollClassOptions) {
    const sectionRefs = Array(count)
        .fill(0)
        .map(() => createRef<HTMLElement>());
    const dotRefs = Array(count)
        .fill(0)
        .map(() => createRef<HTMLElement>());

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const sectionIndex = sectionRefs.findIndex((ref) => ref.current === entry.target);
                const dotRef = dotRefs[sectionIndex];

                if (dotRef && entry.isIntersecting && dotRef.current) {
                    dotRef.current.classList.add(className);
                } else if (dotRef && dotRef.current) {
                    dotRef.current.classList.remove(className);
                }
            });
        }, options);

        sectionRefs.forEach((ref) => {
            if (ref.current) {
                observer.observe(ref.current);
            }
        });

        return () => {
            sectionRefs.forEach((ref) => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            });
        };
    }, [sectionRefs, dotRefs, className, options]);

    return { sectionRefs, dotRefs };
}
