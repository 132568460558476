import { json, LoaderFunction, type MetaFunction } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { useState } from 'react';
import arrow_02 from '@/assets/images/arrow_02.png';
import main_back from '@/assets/images/main_back.png';
import meal_01 from '@/assets/images/meal_01.png';
import meal_02 from '@/assets/images/meal_02.png';
import meal_03 from '@/assets/images/meal_03.png';
import { useScrollClass } from '@hooks/useScrollClass';
import { getFoodTables } from '@server/home/food-table/food.table.client.server';
import { FoodTableDTO } from '@server/home/food-table/food.table.models';

export const meta: MetaFunction = () => {
    return [{ title: '꼬륵 > 메인' }, { name: 'description', content: '[꼬륵] 메인 페이지' }];
};

export const loader: LoaderFunction = async ({ request }) => {
    const foodTable = await getFoodTables(request);
    return json({ foodTable: foodTable.content! || [] });
};

export default function Index() {
    const { foodTable } = useLoaderData<typeof loader>() as { foodTable: FoodTableDTO[] };
    const [index, setIndex] = useState(0);

    const { sectionRefs, dotRefs } = useScrollClass(3, 'act', {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
    });

    const handlePrevClick = (e) => {
        e.preventDefault();
        if (index > 0) {
            setIndex(index - 1);
        }
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        if (index < foodTable.length - 1) {
            setIndex(index + 1);
        }
    };

    return (
        <div className={'content'}>
            <section ref={sectionRefs[0]} className={'main_sec'} data-idx={'1'}>
                <div className={'in'}>
                    <img className={'main_img'} src={main_back} alt={''} />
                    <div className={'main_dot_list'}>
                        <div className={'con'}>
                            <span ref={dotRefs[0]} className={`dot `} data-idx={'1'}></span>
                            <span ref={dotRefs[1]} className={`dot `} data-idx={'2'}></span>
                            <span ref={dotRefs[2]} className={`dot `} data-idx={'3'}></span>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={sectionRefs[1]} className={'bs_sec main_sec'} data-idx={'2'}>
                <div className={'mx1440'}>
                    <p className={'sub_tit'}>{'WEEKLY MENU'}</p>
                    {foodTable.length > 0 && (
                        <>
                            <div className="week_date_con">
                                <button className="week_btn prev" onClick={handlePrevClick}>
                                    <img className="com_img" src={arrow_02} alt="" />
                                </button>
                                <p className="week_date">
                                    {foodTable[index].mon.date ?? ''} ~ {foodTable[index].fri.date ?? ''}
                                </p>
                                <button className="week_btn next" onClick={handleNextClick}>
                                    <img className="com_img" src={arrow_02} alt="" />
                                </button>
                            </div>
                            <div className="menu_list_slide">
                                <div className={`main_menu_list now`}>
                                    <div key={index} className="week_unit">
                                        <FoodUnit food={foodTable[index]} day="mon" />
                                        <FoodUnit food={foodTable[index]} day="tue" />
                                        <FoodUnit food={foodTable[index]} day="wed" />
                                        <FoodUnit food={foodTable[index]} day="thu" />
                                        <FoodUnit food={foodTable[index]} day="fri" />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </section>

            <section ref={sectionRefs[2]} className={'bs_sec last main_sec'} data-idx={'3'}>
                <div className={'mx1440'}>
                    <p className={'sub_tit'}>{'MENU'}</p>
                    <div className={'menu_dtl_con'}>
                        <div className={'list'}>
                            <div className={'unit'}>
                                <div className={'meal_img'}>
                                    <img className={'com_img'} src={meal_01} alt={''} />
                                </div>
                                <p className={'meal_tit'}>{'BASIC BOX'}</p>
                                <p className={'meal_copy'}>{'기본 도시락'}</p>
                            </div>
                            <div className={'unit'}>
                                <div className={'meal_img'}>
                                    <img className={'com_img'} src={meal_01} alt={''} />
                                </div>
                                <p className={'meal_tit'}>{'SENSIBLE BOX'}</p>
                                <p className={'meal_copy'}>{'실속형 도시락'}</p>
                            </div>
                            <div className={'unit'}>
                                <div className={'meal_img'}>
                                    <img className={'com_img'} src={meal_02} alt={''} />
                                </div>
                                <p className={'meal_tit'}>{'PREMIUM BOX'}</p>
                                <p className={'meal_copy'}>{'프리미엄 도시락'}</p>
                            </div>
                            <div className={'unit'}>
                                <div className={'meal_img'}>
                                    <img className={'com_img'} src={meal_03} alt={''} />
                                </div>
                                <p className={'meal_tit'}>{'SANDWICH'}</p>
                                <p className={'meal_copy'}>{'샌드위치'}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function FoodUnit({ food, day }: { food: FoodTableDTO; day: 'mon' | 'tue' | 'wed' | 'thu' | 'fri' }) {
    const tops = food[day].list.filter((item) => item.top === 'Y') || [];
    const menus = food[day].list.filter((item) => item.top !== 'Y') || [];

    return (
        <div className="unit">
            <p className={`date_copy ${day}`}>{day && day.toUpperCase()}</p>
            <div className="menu_con">
                {tops.map((item, index) => (
                    <p key={`${item.menu + '_top_' + index}`} className={'menu_copy'}>
                        {item.menu}
                    </p>
                ))}
            </div>
            <div className={'menu_con'}>
                {menus.map((item, index) => (
                    <p key={`${item.menu + '_menu_' + index}`} className={'menu_copy'}>
                        {item.menu}
                    </p>
                ))}
            </div>
        </div>
    );
}
